import { yupResolver } from '@hookform/resolvers/yup';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Divider, Grid, InputLabel, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { ControlledCheckbox } from '../../../components/basics/ControlledCheckbox';
import { ControlledComboBox, SelectOption } from '../../../components/basics/ControlledComboBox';
import { ControlledTextInput } from '../../../components/basics/ControlledTextInput';
import { Budget, BudgetFacilitators } from '../../../models/Budget';
import { Company } from '../../../models/Company';
import { BudgetService } from '../../../services/Budget.service';
import { UserCompaniesService } from '../../../services/UserCompanies.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { BudgetScheme } from '../../../utils/forms/validations/formValidations';
import { findSelectedOption, setInputErrorsFromApi } from '../../../utils/utils';
import { useBudgetContext } from '../context/BudgetContext';

interface Props {
  editItem?: Budget;
  onClose: () => void;
}
const months: SelectOption[] = [
  { id: 1, name: 'Janeiro' },
  { id: 2, name: 'Fevereiro' },
  { id: 3, name: 'Março' },
  { id: 4, name: 'Abril' },
  { id: 5, name: 'Maio' },
  { id: 6, name: 'Junho' },
  { id: 7, name: 'Julho' },
  { id: 8, name: 'Agosto' },
  { id: 9, name: 'Setembro' },
  { id: 10, name: 'Outubro' },
  { id: 11, name: 'Novembro' },
  { id: 12, name: 'Dezembro' }
];
const copyValuesOptions: SelectOption[] = [
  { id: null, name: 'Nenhum' },
  { id: 'year', name: 'Orçamento do Ano Anterior' },
  { id: 'month', name: 'Mês Referência do Ano Anterior' }
];
const formatPercentage = (value: string) => {
  if (value != '') {
    value = value.replace('%', '');
    value = value.replace(',', '.');
    return value;
  }
  return '0';
};
export const BudgetFormDialog = ({ editItem, onClose }: Props) => {
  const dispatch = useDispatch();
  const [companies, setCompanies] = useState<SelectOption[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const { fetchBudget } = useBudgetContext();
  const showFacilitators = editItem?.id ? false : true;
  const getDefaultValues = (): Budget => {
    return {
      reference_year: editItem?.reference_year ?? '',
      reference_month: editItem?.reference_month ? findSelectedOption(months, editItem?.reference_month) : findSelectedOption(months, 11),
      company: editItem?.company ? ({ id: editItem.company.id, name: editItem.company.company_name } as SelectOption) : null,
      use_readjustment: false
    };
  };

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm<BudgetFacilitators>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(BudgetScheme),
    defaultValues: getDefaultValues()
  });
  const watchCopyValues = useWatch({
    control,
    name: 'copy_values',
    defaultValue: ''
  });

  const watchUseReadjustment = useWatch({
    control,
    name: 'use_readjustment',
    defaultValue: false
  });

  const watchMonthsReplicate = useWatch({
    control,
    name: 'months_replicate',
    defaultValue: []
  });
  const preparatePayload = async (data: BudgetFacilitators) => {
    let payload = {
      company: data?.company?.id,
      reference_year: data?.reference_year,
      reference_month: data?.reference_month?.id,
      use_readjustment: data?.use_readjustment,
      copy_values: data?.copy_values?.id
    } as BudgetFacilitators;
    if (data?.copy_values?.id === 'year' && data?.use_readjustment) {
      payload = {
        ...payload,
        january: formatPercentage(data?.january!),
        february: formatPercentage(data?.february!),
        march: formatPercentage(data?.march!),
        may: formatPercentage(data?.may!),
        june: formatPercentage(data?.june!),
        july: formatPercentage(data?.july!),
        august: formatPercentage(data?.august!),
        september: formatPercentage(data?.september!),
        october: formatPercentage(data?.october!),
        november: formatPercentage(data?.november!),
        december: formatPercentage(data?.december!)
      };
    }
    if (data?.copy_values?.id === 'month') {
      if (data?.months_replicate?.length <= 0) {
        setError('months_replicate', {
          type: 'format',
          message: 'Por favor, Selecione pelo menos um mês'
        });
        return false;
      }
      const monthsIdsList = data?.months_replicate?.map((month) => month.id);
      const readjustments = data?.months_replicate?.reduce((acc, month) => {
        acc[month.id] = formatPercentage(data?.readjustments?.[month.id] || '0');
        return acc;
      }, {});

      payload = {
        ...payload,
        months_replicate: monthsIdsList,
        readjustments
      };
    }
    return payload;
  };
  const createOrUpdateBudget: SubmitHandler<Budget> = async (data: BudgetFacilitators) => {
    dispatch(showSpinner());
    try {
      const payload = await preparatePayload(data);
      if (!payload) {
        return;
      }
      editItem?.id ? await BudgetService.update({ id: editItem?.id, ...payload }) : await BudgetService.create(payload);
      dispatch(
        showSnackbarAlert({
          title: 'Sucesso',
          message: editItem?.id ? 'Orçamento atualizado com sucesso!' : 'Orçamento criado com sucesso!',
          severity: 'success'
        })
      );
      fetchBudget();
      onClose();
    } catch (error: any) {
      const formError = setInputErrorsFromApi(setError, error.data);
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: formError?.join(','),
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };
  const fetchCompanies = async () => {
    try {
      const response = await UserCompaniesService.get({ use_budget: true });
      if (response) {
        const result = response?.data?.results;
        setCompanies(
          result.map((company: Company) => ({
            id: company.id,
            name: company.company_name
          }))
        );
      }
    } catch (error) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: 'Erro ao buscar empresas',
          severity: 'error'
        })
      );
    }
  };
  useEffect(() => {
    const loadData = async () => {
      dispatch(showSpinner());
      setLoading(true);

      await fetchCompanies();

      setLoading(false);
      dispatch(hideSpinner());
    };

    loadData();
  }, []);

  return (
    <Dialog open={!loading} onClose={onClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        {editItem?.id ? 'Editar' : 'Novo'} Orçamento
      </DialogTitle>
      <Divider />
      <form onSubmit={handleSubmit(createOrUpdateBudget)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="company">Empresa</InputLabel>
              <ControlledComboBox
                name="company"
                placeholder="Empresa"
                control={control}
                errorMessage={errors.company?.message?.toString()}
                selectOptions={companies}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="reference_year">Ano</InputLabel>
              <ControlledTextInput
                name="reference_year"
                placeholder="Ano"
                control={control}
                errorMessage={errors.reference_year?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="reference_month">Mês Referência</InputLabel>
              <ControlledComboBox
                placeholder="Mês Referência"
                name="reference_month"
                control={control}
                errorMessage={errors.reference_month?.message?.toString()}
                selectOptions={months}
              />
            </Grid>
            {showFacilitators && (
              <>
                <Grid item xs={12} md={12}>
                  <Divider />
                </Grid>
                <Grid container item xs={12} sm={12} alignItems="center">
                  <Typography variant="h5" component="div" color="primary">
                    Replicar Valores:
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputLabel htmlFor="copy_values">Copiar Valores de:</InputLabel>
                  <ControlledComboBox
                    placeholder="Copiar Valores de"
                    name="copy_values"
                    control={control}
                    errorMessage={errors.copy_values?.message?.toString()}
                    selectOptions={copyValuesOptions}
                  />
                </Grid>
                {watchCopyValues?.id === 'month' && (
                  <Grid item xs={12} md={6}>
                    <InputLabel htmlFor="months_replicate">Replicar para meses</InputLabel>
                    <ControlledComboBox
                      name="months_replicate"
                      control={control}
                      errorMessage={errors.months_replicate?.message?.toString()}
                      selectOptions={months}
                      multiple={true}
                      allowSelectAll={true}
                    />
                  </Grid>
                )}
                <Grid item xs={12} md={6}>
                  <Typography variant="body1">Usar Reajuste?</Typography>
                  <ControlledCheckbox name="use_readjustment" label={watchUseReadjustment ? 'Sim' : 'Não'} control={control} />
                </Grid>
                {watchCopyValues?.id === 'year' && watchUseReadjustment && (
                  <>
                    <Grid container item xs={12} sm={12} alignItems="center">
                      <Typography variant="h5" component="div" color="primary">
                        Reajustes:
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <InputLabel htmlFor="january">Janeiro</InputLabel>
                      <ControlledTextInput
                        name="january"
                        control={control}
                        percentage={true}
                        errorMessage={errors.january?.message?.toString()}
                      />
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <InputLabel htmlFor="february">Fevereiro</InputLabel>
                      <ControlledTextInput
                        name="february"
                        control={control}
                        percentage={true}
                        errorMessage={errors.february?.message?.toString()}
                      />
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <InputLabel htmlFor="march">Março</InputLabel>
                      <ControlledTextInput
                        name="march"
                        control={control}
                        percentage={true}
                        errorMessage={errors.march?.message?.toString()}
                      />
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <InputLabel htmlFor="april">Abril</InputLabel>
                      <ControlledTextInput
                        name="april"
                        control={control}
                        percentage={true}
                        errorMessage={errors.april?.message?.toString()}
                      />
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <InputLabel htmlFor="may">Maio</InputLabel>
                      <ControlledTextInput name="may" control={control} percentage={true} errorMessage={errors.may?.message?.toString()} />
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <InputLabel htmlFor="june">Junho</InputLabel>
                      <ControlledTextInput
                        name="june"
                        control={control}
                        percentage={true}
                        errorMessage={errors.june?.message?.toString()}
                      />
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <InputLabel htmlFor="july">Julho</InputLabel>
                      <ControlledTextInput
                        name="july"
                        control={control}
                        percentage={true}
                        errorMessage={errors.july?.message?.toString()}
                      />
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <InputLabel htmlFor="august">Agosto</InputLabel>
                      <ControlledTextInput
                        name="august"
                        control={control}
                        percentage={true}
                        errorMessage={errors.august?.message?.toString()}
                      />
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <InputLabel htmlFor="september">Setembro</InputLabel>
                      <ControlledTextInput
                        name="september"
                        control={control}
                        percentage={true}
                        errorMessage={errors.september?.message?.toString()}
                      />
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <InputLabel htmlFor="october">Outubro</InputLabel>
                      <ControlledTextInput
                        name="october"
                        control={control}
                        percentage={true}
                        errorMessage={errors.october?.message?.toString()}
                      />
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <InputLabel htmlFor="november">Novembro</InputLabel>
                      <ControlledTextInput
                        name="november"
                        control={control}
                        percentage={true}
                        errorMessage={errors.november?.message?.toString()}
                      />
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <InputLabel htmlFor="december">Dezembro</InputLabel>
                      <ControlledTextInput
                        name="december"
                        control={control}
                        percentage={true}
                        errorMessage={errors.december?.message?.toString()}
                      />
                    </Grid>
                  </>
                )}
                {watchCopyValues?.id === 'month' && watchUseReadjustment && (
                  <>
                    <Grid container item xs={12} sm={12} alignItems="center">
                      <Typography variant="h5" component="div" color="primary">
                        Reajustes por Mês:
                      </Typography>
                    </Grid>
                    {watchMonthsReplicate?.map((month: SelectOption) => (
                      <Grid key={month?.id} item xs={12} md={2}>
                        <InputLabel htmlFor={`readjustment_${month?.name}`}>{month?.name}</InputLabel>
                        <ControlledTextInput
                          name={`readjustments.${month?.id}`}
                          placeholder="Reajuste (%)"
                          control={control}
                          percentage={true}
                          errorMessage={errors?.readjustments?.[month.id]?.message?.toString()}
                        />
                      </Grid>
                    ))}
                  </>
                )}
              </>
            )}
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ mx: 2, my: 1 }}>
          <Button startIcon={<CloseIcon />} variant="outlined" onClick={onClose}>
            Cancelar
          </Button>
          <Button startIcon={<CheckIcon />} variant="contained" type="submit">
            Salvar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
